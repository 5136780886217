import React from "react";
import PropTypes from "prop-types";
import { useBasketStore } from "../store";
import { apiHeaders } from "../utils";

export const BasketItem = ({
	updateUrl,
	name,
	imageUrl,
	quantity,
	variants,
	total,
	classModifier
}) => {
	const [fetching, setFetching] = React.useState(false);
	const { updateBasket } = useBasketStore();
	const classPrefix = "basket-item";

	async function updateQuantity(amount) {
		await fetch(updateUrl, {
			method: "PUT",
			body: JSON.stringify({
				quantity: amount
			}),
			credentials: "include",
			headers: apiHeaders()
		});
		setFetching(false);
		updateBasket();
	}

	const removeFromCart = async () => {
		setFetching(true);
		await fetch(updateUrl, {
			method: "DELETE",
			credentials: "include"
		});
		updateBasket();
	};

	const incrementQuantity = () => {
		if (fetching) return;
		setFetching(true);
		updateQuantity(quantity + 1);
	};

	const decrementQuantity = () => {
		if (fetching) return;
		setFetching(true);
		updateQuantity(quantity - 1);
	};

	const classBuilder = element => {
		if (classModifier) {
			return `${classPrefix}__${element} ${classPrefix}__${element}--${classModifier}`;
		}
		return `${classPrefix}__${element}`;
	};

	const removeButtonIcon = () => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.7a1 1 0 0 0-1.42 1.42l4.88 4.88-4.88 4.88a1 1 0 0 0 1.42 1.42l4.88-4.88 4.88 4.88a1 1 0 0 0 1.42-1.42l-4.88-4.88 4.88-4.88a1 1 0 0 0 0-1.42z" />
			</svg>
		);
	};

	const variantsList = [];
	Object.entries(variants).forEach(([name, value]) => {
		variantsList.push(
			<div key={name} className={classBuilder("variant")}>
				{name}: {value}
			</div>
		);
	});

	return (
		<div className={classPrefix + (fetching ? " is-loading" : "")}>
			<img className={classBuilder("image")} src={imageUrl} />
			<div className={classBuilder("info")}>
				<div className={classBuilder("name")}>{name}</div>
				<div>
					{variantsList}
					<div className={classBuilder("quantity")}>
						QTY:
						{quantity > 1 ? (
							<button
								onClick={decrementQuantity}
								className={classBuilder("decrease")}
								type="button"
							>
								<svg>
									<use xlinkHref="#icon-arrow-left" />
								</svg>
							</button>
						) : (
							<div></div>
						)}
						{quantity}
						<button
							onClick={incrementQuantity}
							className={classBuilder("increase")}
							type="button"
						>
							<svg>
								<use xlinkHref="#icon-arrow-right" />
							</svg>
						</button>
					</div>
				</div>
				<p className={classBuilder("total")}>Total: ${total}</p>
				<button
					type="button"
					className={classBuilder("remove")}
					onClick={removeFromCart}
				>
					{classModifier === "summary" ? removeButtonIcon() : "Remove"}
				</button>
			</div>
		</div>
	);
};

BasketItem.propTypes = {
	name: PropTypes.string,
	imageUrl: PropTypes.string,
	quantity: PropTypes.number,
	total: PropTypes.string,
	updateUrl: PropTypes.string,
	variants: PropTypes.object,
	classModifier: PropTypes.string
};
