import React, { useEffect } from "react";
import { BasketItem } from "./BasketItem";
import { useBasketStore } from "../store";
import { FormProvider, useForm } from "react-hook-form";
import { apiHeaders } from "../utils";
import { CouponForm } from "./CouponForm";
import { DeliveryForm } from "./DeliveryForm";
import { PortalComponent } from "../store";
import { Spinner } from "./Spinner";

const BasketSummary = ({ items }) => {
	return items.map(item => {
		return (
			<BasketItem
				classModifier="summary"
				key={item.ref}
				updateUrl={item.url}
				name={item.product.name}
				imageUrl={item.product.image_url}
				variants={item.extra}
				quantity={item.quantity}
				total={item.total}
			/>
		);
	});
};

const paywayStyles = {
	".payway-card": {
		margin: "30px 0 0 0",
		width: "100%!important",
		background: "white",
		border: "none",
		position: "static!important"
	},
	".payway-card input, .payway-card select": {
		"box-sizing": "border-box",
		background: "#f3f3f3",
		border: "1px solid #f3f3f3",
		position: "static!important",
		width: "100%!important",
		display: "block!important",
		height: "auto!important"
	},
	".payway-card label, .payway-card .payway-expiration": {
		position: "static!important",
		width: "100%!important",
		display: "block!important",
		height: "auto!important",
		"text-align": "left!important",
		"margin-bottom": "1rem!important"
	},
	".payway-card label.payway-number input.payway-hidden": {
		position: "absolute!important"
	},
	".payway-card .payway-expiration .payway-month, .payway-card .payway-expiration .payway-year": {
		width: "49%!important",
		display: "inline-block!important"
	},
	".payway-card .payway-expiration .payway-year": {
		"margin-left": "2%!important"
	},
	".payway-card .payway-creditcard-expirationseparator": {
		display: "none!important"
	},
	".payway-card select:focus, .payway-card input:focus": {
		"box-sizing": "border-box"
	},
	".payway-card label, .payway-card legend": {
		color: "black",
		"font-weight": "300",
		"line-height": "2",
		height: "auto"
	},
	".payway-card label.payway-number": {
		width: "65%!important",
		"margin-right": "2%!important",
		display: "inline-block!important"
	},
	".payway-card label.payway-csc": {
		width: "33%!important",
		display: "inline-block!important"
	}
};

const TabHeading = ({ title, targetStage, currentStage, onClick }) => {
	return (
		<h3
			className={`checkout__tab ${
				targetStage == currentStage ? "checkout__tab--active" : ""
			} ${targetStage < currentStage ? "checkout__tab--complete" : ""}`}
			onClick={onClick}
		>
			{title}
		</h3>
	);
};

const CheckoutMain = ({
	stage,
	changeStage,
	basket,
	canContinue,
	nextText,
	children
}) => {
	if (!basket || basket.items?.length == 0) {
		return <div className="checkout__empty">Your cart is empty.</div>;
	}

	const headingClick = targetStage => {
		if (targetStage < stage) {
			changeStage(targetStage);
		}
	};

	return (
		<div className="checkout__content">
			<div className="checkout__main">
				<div className="checkout__tabs">
					<TabHeading
						title="Your basket"
						targetStage={1}
						currentStage={stage}
						onClick={() => headingClick(1)}
					/>
					<div className="checkout__tab--divider"></div>
					<TabHeading
						title="Delivery"
						targetStage={2}
						currentStage={stage}
						onClick={() => headingClick(2)}
					/>
					<div className="checkout__tab--divider"></div>
					<TabHeading
						title="Payment"
						targetStage={3}
						currentStage={stage}
						onClick={() => headingClick(3)}
					/>
				</div>
				{children}
			</div>
			<aside className="checkout__sidebar">
				<h3 className="checkout__order-summary">Order Summary</h3>
				<div className="checkout__subtotal">
					Subtotal <span>${basket.subtotal}</span>
				</div>
				<div className="checkout__postage">
					{basket.extra_rows.map(row => {
						return (
							<div key={row.label}>
								<div>{row.label}</div>
								<div className="checkout__delivery">
									{row.extra?.description}{" "}
									<span>{row.extra?.amount_display || `$${row.amount}`}</span>
								</div>
							</div>
						);
					})}
				</div>
				<CouponForm />
				<hr />
				<div className="checkout__total">
					Total (AUD) <span>${basket.total}</span>
				</div>
				<button
					type="submit"
					disabled={!canContinue}
					className="btn btn--store btn--dark checkout-button"
				>
					{nextText}
				</button>
			</aside>
		</div>
	);
};

const PaymentOverlay = ({ visible }) => {
	return (
		<div className={"checkout__overlay" + (visible ? " is-active" : "")}>
			<div className="checkout__overlay-content">
				<p>Your payment is being processed.</p>
				<p>Please do not close this window.</p>
				<Spinner />
			</div>
		</div>
	);
};

export const CheckOut = () => {
	/*
		Checkout component. fetches the basket from the api and processes it
	*/
	const { basket, loading } = useBasketStore();
	const [stage, setStage] = React.useState(1);
	const [paywayFrame, setPaywayFrame] = React.useState(null);
	const [canContinue, setCanContinue] = React.useState(true);
	const [paywayError, setPaywayError] = React.useState(null);
	const [paymentSubmitted, setPaymentSubmitted] = React.useState(false);
	const paywayContainer = React.createRef(null);

	const deliveryForm = useForm({
		defaultValues: {
			country: "Australia",
			state: "Tasmania"
		}
	});
	const [deliveryDetails, setDeliveryDetails] = React.useState(null);

	useEffect(() => {
		if (stage != 3) {
			setCanContinue(true);
			if (paywayFrame) {
				// We've been to stage 3 and come back, destroy the payway frame
				paywayFrame.destroy();
				setPaywayFrame(null);
			}
		}
		if (stage == 3 && paywayFrame == null) {
			const paywayPublicKey = document
				.querySelector("#checkout")
				.getAttribute("data-public-key");

			setCanContinue(false);
			payway.createCreditCardFrame(
				{
					publishableApiKey: paywayPublicKey,
					tokenMode: "callback",
					onValid: () => setCanContinue(true),
					onInvalid: () => setCanContinue(false),
					style: paywayStyles,
					height: 350,
					width: paywayContainer.current.offsetWidth
				},
				(err, frame) => {
					if (err) {
						console.log(err);
						return;
					}
					setPaywayFrame(frame);
				}
			);
		}
	}, [stage]);

	const orderCheckLoop = orderRef => {
		let orderInterval = setInterval(() => {
			fetch(`/store-api/order-status/${orderRef}/`)
				.then(response => response.json())
				.then(data => {
					const status = data.status;

					if (status === "FAILED") {
						clearInterval(orderInterval);
						setPaymentSubmitted(false);
						setPaywayError(data.failure_reason);
						setCanContinue(true);
					}
					if (status === "PROCESSING") {
						fetch("/store-api/basket/", {
							method: "DELETE"
						}).then(() => {
							window.location.href = data.customer_url;
						});
					}
					// Payway hasn't finished, keep checking
				});
		}, 2000);
	};

	const changeStage = stage => {
		setStage(stage);
	};

	const submitOrder = paymentToken => {
		const address = `${deliveryDetails.name}, ${deliveryDetails.address}, ${deliveryDetails.city}, ${deliveryDetails.state}, ${deliveryDetails.postcode}`;
		let body = {
			name: deliveryDetails.name,
			payment_token: paymentToken,
			payment_method: "westpac-payway",
			email: deliveryDetails.email,
			shipping_address: address,
			billing_address: address
		};

		setPaymentSubmitted(true);

		fetch("/store-api/checkout/", {
			method: "POST",
			headers: apiHeaders(),
			credentials: "include",
			body: JSON.stringify(body),
			keepalive: true
		})
			.then(response => {
				return response.json();
			})
			.then(data => {
				if (data.success) {
					orderCheckLoop(data.order_ref);
				} else {
					setPaymentSubmitted(false);
					setPaywayError(data.reason);
					setCanContinue(true);
				}
			});
	};

	let lhs = null;
	let nextText = "Proceed to Delivery";
	let formSubmit = e => {
		e.preventDefault();
		changeStage(stage + 1);
	};
	switch (stage) {
		case 1:
			lhs = (
				<div className="checkout__items">
					<BasketSummary items={basket?.items} />
				</div>
			);
			break;
		case 2:
			const shippingPlaceholder = document.querySelector("#shipping-message");
			lhs = (
				<FormProvider {...deliveryForm}>
					<div
						className="checkout__delivery-warning"
						dangerouslySetInnerHTML={{ __html: shippingPlaceholder?.innerHTML }}
					></div>
					<DeliveryForm />
				</FormProvider>
			);
			nextText = "Proceed to Payment";
			formSubmit = deliveryForm.handleSubmit(data => {
				setDeliveryDetails(data);
				changeStage(stage + 1);
			});
			break;
		case 3:
			lhs = (
				<div ref={paywayContainer}>
					<h4 className="checkout__subtitle">Payment information</h4>
					<div id="payway-credit-card"></div>
					{paywayError && (
						<div className="checkout__error">
							Payment unsuccessful. Reason: {paywayError}
						</div>
					)}
				</div>
			);
			nextText = "Pay Now";
			formSubmit = e => {
				setCanContinue(false);
				e.preventDefault();
				paywayFrame.getToken((err, data) => {
					if (err) {
						console.log(err);
						return;
					}
					submitOrder(data.singleUseTokenId);
				});
			};
			break;
		default:
			lhs = (
				<div className="checkout__items">
					<BasketSummary items={basket?.items} />
				</div>
			);
			break;
	}

	return (
		<>
			<div className="l-prose-blocks">
				<div className="l-prose-blocks__block is-visible">
					{loading ? (
						<Spinner />
					) : (
						<form onSubmit={formSubmit} className="checkout" method="POST">
							<div className="checkout__banner">
								<h2 className="checkout__title">Checkout</h2>
							</div>
							<a href="/store" className="checkout__continue-shopping">
								Continue shopping
							</a>
							<CheckoutMain
								basket={basket}
								stage={stage}
								changeStage={changeStage}
								canContinue={canContinue}
								nextText={nextText}
							>
								{lhs}
							</CheckoutMain>
						</form>
					)}
				</div>
			</div>
			<PortalComponent elementId="payment-overlay">
				<PaymentOverlay visible={paymentSubmitted} />
			</PortalComponent>
		</>
	);
};
