export function getCSRFToken() {
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		if (cookie.includes("csrftoken")) {
			return cookie.split("=")[1];
		}
	}
}

export function apiHeaders() {
	return {
		"Content-Type": "application/json",
		"X-CSRFToken": getCSRFToken()
	};
}

export function createFormData(data) {
	const formData = new FormData();
	for (const [key, value] of Object.entries(data)) {
		if (value instanceof FileList || value instanceof Array) {
			for (let i = 0; i < value.length; i++) {
				formData.append(key, value[i]);
			}
		} else {
			formData.append(key, value);
		}
	}
	formData.append("csrfmiddlewaretoken", getCSRFToken());
	return formData;
}
