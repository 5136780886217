import React from "react";
import { BasketItem } from "./BasketItem";
import { useBasketStore } from "../store";

export const Basket = () => {
	const { basket, dropdownOpen, toggleDropdown } = useBasketStore();
	const dropdown = React.useRef(null);

	if (dropdown.current)
		dropdown.current.style.transition = "opacity 250ms ease-in-out";

	return (
		<div
			ref={dropdown}
			className={"cart-dropdown" + (dropdownOpen ? " is-open" : "")}
		>
			<div className="cart-dropdown__banner">
				Cart
				<button
					onClick={() => {
						toggleDropdown();
					}}
					className="cart-dropdown__close"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
						<path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.7a1 1 0 0 0-1.42 1.42l4.88 4.88-4.88 4.88a1 1 0 0 0 1.42 1.42l4.88-4.88 4.88 4.88a1 1 0 0 0 1.42-1.42l-4.88-4.88 4.88-4.88a1 1 0 0 0 0-1.42z" />
					</svg>
				</button>
			</div>
			{basket?.items?.map(item => {
				return (
					<BasketItem
						key={item.ref}
						updateUrl={item.url}
						name={item.product.name}
						imageUrl={item.product.image_url}
						quantity={item.quantity}
						variants={item.extra}
						total={item.total}
					/>
				);
			})}
			{basket?.items?.length === 0 ? (
				<div>Your cart is empty.</div>
			) : (
				[
					/* TODO use better href */
					<a
						key="checkout"
						href="/store/checkout"
						className="btn btn--store btn--dark btn--cart-checkout"
					>
						CHECKOUT
					</a>,
					<button
						key="continue-shopping"
						type="button"
						onClick={() => toggleDropdown()}
						className="btn btn--store btn--dark btn--cart-continue"
					>
						CONTINUE SHOPPING
					</button>
				]
			)}
		</div>
	);
};
